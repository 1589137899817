import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import imgLogo from '../images/logo.jpg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const Header = () => {
    const [navbarVisibility, setNavbarvisibility] = useState(false);
    const { t } = useTranslation();

    function onClickHandler(e){
        let id = e.target.parentElement.id;

        // Si el link tiene dos palabras se le puede hacer click en un espacio
        // en blanco del <li> en vez del <a>, y el id de arriba sería null.
        // El siguiente condicional es para corregir el problema.
        if (id === '') {
            id = e.target.id;
        }

        const navLinks = document.querySelectorAll('.nav__link');
        navLinks.forEach((link) => {
            link.classList.remove('nav__link--active');
        });

        if (e.target.parentNode.parentElement.id !== 'header-logo') {
            const activeLink = document.getElementById(id);
            activeLink.classList.add('nav__link--active');
        }

        if (navbarVisibility) {
            toggleNavbar();
        }
    }

    function toggleNavbar() {
        const navbar = document.getElementById('header__nav');
        const burguer = document.getElementById('nav__burguer');
        navbar.classList.toggle('header__nav--visible');
        burguer.classList.toggle('nav__burguer--visible');
        
        setNavbarvisibility(!navbarVisibility);
    }

    function changeLanguageHandler() {
        if (navbarVisibility) {
            toggleNavbar();
        }
    }


    return(
        <header className="header">
            { navbarVisibility ? 
                <div className="nav__background" id="nav__background" onClick={toggleNavbar}></div> 
                : null
            }
            <div className="header__logo" id="header-logo" onClick={(e) => onClickHandler(e)}>
                <Link to="/"><img src={imgLogo} alt="Logo" /></Link>
            </div>
            <nav id="header__nav" className="header__nav">
                <ul>
                    <li className="nav__link" id="home" onClick={(e) => onClickHandler(e)}>
                        <Link to="/">{t('HEADER__INICIO')}</Link>
                    </li>
                    <li className="nav__link" id="european-center" onClick={(e) => onClickHandler(e)}>
                        <Link to="/hydrogen-european-center">{t('HEADER__CENTRO-EUROPEO')}</Link>
                    </li>
                    <li className="nav__link" id="hydrogen-comunity" onClick={(e) => onClickHandler(e)}>
                        <Link to="/hydrogen-comunity">{t('HEADER__COMUNIDAD-HIDROGENO')}</Link>
                    </li>
                    <li className="nav__link" id="tecnology" onClick={(e) => onClickHandler(e)}>
                        <Link to="/tecnology">{t('HEADER__TECNOLOGIA')}</Link>
                    </li>
                    <li className="nav__link" id="projects" onClick={(e) => onClickHandler(e)}>
                        <Link to="/projects">{t('HEADER__PROYECTOS')}</Link>
                    </li>
                    <li className="nav__link" id="transport" onClick={(e) => onClickHandler(e)}>
                        <Link to="/transport">{t('HEADER__TRANSPORTE')}</Link>
                    </li>
                    <li className="nav__link" id="contact" onClick={(e) => onClickHandler(e)}>
                        <Link to="/contact">{t('HEADER__CONTACTO')}</Link>
                    </li>
                    <li><LanguageSelector location="HEADER" changeLanguageHandler={changeLanguageHandler} /></li>
                </ul>
            </nav>
            <span id="nav__burguer" className="nav__burguer material-icons" onClick={toggleNavbar}>menu</span>
        </header>
    )
}

export default Header;
