import React, { useState } from 'react';
import axios from 'axios';
import './Contacto.css';
import imgContacto from '../images/contacto.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Contacto = () => {
    const { t } = useTranslation();
    const [form, setForm] = useState({ nombre : '',
                                       empresa : '',
                                       email : '',
                                       telefono : '',
                                       mensaje : '' })
    const [mensaje, setMensaje] = useState(<p></p>);

    function handleChange(e) {
        const campo = e.target.name;
        setForm({ ...form,
                  [campo] : e.target.value })
    }

    function handleSubmit(e) {
        e.preventDefault();
        axios({
            method: 'post',
            url: 'http://www.hydrogenium.es/api/submit.php',
            headers: { 'content-type': 'application/json' },
            data: form
          })
            .then(result => {
                if (result.data.enviado) {
                    setMensaje(<p className="send_ok">{result.data.mensaje}</p>);
                    setForm({ nombre : '',
                              empresa : '',
                              email : '',
                              telefono : '',
                              mensaje : '' })
                } else {
                    setMensaje(<p className="send_error">{result.data.mensaje}</p>);
                }
            })
            .catch(error => setMensaje(<p className="send_error">{error.message}</p>));
    }

    return(
        <>
            <Helmet>
                <title>Contacta con nosotros</title>
                <meta name="description" content="¿Tienes alguna duda? Contacta con nosotros rellenando el formulario de la página." />
            </Helmet>
            
            <div className="page__container">
                <h2>{t('CONTACTO__H2')}</h2>
                <img src={imgContacto} className="page__img" alt="" />
                <div className="formulario__container">
                    <h3>{t('CONTACTO__H3')}</h3>
                    <form onSubmit={handleSubmit} className="formulario-contacto">

                        <label htmlFor="nombre">{t('CONTACTO__NOMBRE')}</label>
                        <input type="text" name="nombre" value={form.nombre} onChange={handleChange} />

                        <label htmlFor="empresa">{t('CONTACTO__EMPRESA')}</label>
                        <input type="text" name="empresa" value={form.empresa} onChange={handleChange} />

                        <label htmlFor="email">{t('CONTACTO__EMAIL')}</label>
                        <input type="email" name="email" value={form.email} onChange={handleChange} />
                        
                        <label htmlFor="telefono">{t('CONTACTO__TELEFONO')}</label>
                        <input type="tel" name="telefono" value={form.telefono} onChange={handleChange} />
                        
                        <label htmlFor="mensaje">{t('CONTACTO__MENSAJE')}</label>
                        <textarea name="mensaje" value={form.mensaje} onChange={handleChange} />
                        
                        <button type="submit">{t('CONTACTO__ENVIAR')}</button>
                        
                    </form>

                    <p className="formulario__nota">*{t('CONTACTO__NOTA')}</p>

                    {mensaje}

                </div>

            </div>      
        </>
    )
};

export default Contacto;