import React from 'react';
import './Transporte.css';
import imgTransporte from '../images/transporte-colectivo.jpg';
import imgTransporte1 from '../images/transporte-colectivo1.jpg';
import imgTransporte2 from '../images/transporte-colectivo2.png';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Transporte = () => {
    const { t } = useTranslation();
    
    return(
        <>
        <Helmet>
            <title>Transporte colectivo</title>
            <meta name="description" content="Después del desarollo de vehículos eléctricos e hidrógeno, una revolución en el transporte colectivo aparecerá con los trenes levitantes. Esta tecnología ha sido probada con exito en Asia con el tren Maglev que es un tren superconductor manteniéndose en levitación estable sobre una vía magnética." />
        </Helmet>

        <div className="page__container">
            <h2>{t('TRANSPORTE__H2')}</h2>
            <img src={imgTransporte} className="page__img" alt="" />
            <p>{t('TRANSPORTE__P1')}</p>
            <p>{t('TRANSPORTE__P2')}</p>
            <img src={imgTransporte2} className="page__img transporte__img" alt="" />
            <p>{t('TRANSPORTE__P3')}</p>
            <p>{t('TRANSPORTE__P4')}</p>
            <p>{t('TRANSPORTE__P5')}</p>
            <p>{t('TRANSPORTE__P6')}</p>
            <div className="transporte__fases">
                <h3>{t('TRANSPORTE__RECUADRO__H3')}</h3>
                    <div className="fases__container">
                    <div className="fases__left">
                        <img src={imgTransporte1} className="fases__img" alt="" />
                    </div>
                    <div className="fases__right">
                        <p>{t('TRANSPORTE__RECUADRO__P1')}</p>
                        <p>{t('TRANSPORTE__RECUADRO__P2')}</p>
                        <p>{t('TRANSPORTE__RECUADRO__P3')}</p>
                        <p>{t('TRANSPORTE__RECUADRO__P4')}</p>
                        <p>{t('TRANSPORTE__RECUADRO__P5')}</p>
                        <p>{t('TRANSPORTE__RECUADRO__P6')}</p>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default Transporte;