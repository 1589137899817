import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import langEs from './languages/es.json';
import langEn from './languages/en.json';
import langIt from './languages/it.json';
import langCh from './languages/ch.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "es",
    resources: {
      en: {
        translation: langEn
      },
      es: {
        translation: langEs 
      },
      it: {
        translation: langIt
      },
      ch: {
        translation: langCh
      },
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
});
  
export default i18n;