import React from 'react';
import './Inicio.css';
import img1 from '../images/tecnologia.jpg';
import img2 from '../images/generador-seguridad.jpg';
import img3 from '../images/coche-hidrogeno2.jpg';
import img4 from '../images/ola.jpg';
import img5 from '../images/placas-solares.jpg';
import img6 from '../images/turbina.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Inicio = () => {
    const { t } = useTranslation();

    return(
        <>
            <Helmet>
                <title>Acerca de la empresa</title>
                <meta name="description" content="Hydrogenium European Center S.A pertenece a un grupo de empresas comprometidas con el bienestar de las personas y el entorno medioambiental a través de innovaciones en el campo de las energías." />
            </Helmet>
            
            <div className="page__container">

                <h2>{t('INICIO__H2')}</h2>
                <p>{t('INICIO__P1')}</p>
                <p>{t('INICIO__P2')}</p>
                <div className="images__container">
                    <img src={img1} className="home__img" alt="" />
                    <img src={img2} className="home__img" alt="" />
                    <img src={img3} className="home__img" alt="" />
                    <img src={img4} className="home__img" alt="" />
                    <img src={img5} className="home__img" alt="" />
                    <img src={img6} className="home__img" alt="" />
                </div>
                <h3>{t('INICIO__H3')}</h3>
                <p>{t('INICIO__P3')}</p>
            </div>
        </>
    )
}

export default Inicio;