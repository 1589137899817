import React, { useEffect } from 'react';
import './Particula.css';
import imgParticula from '../images/particula.png';

const Particula = ( {id, size, top, left, tiempoGiro, shadow, active}) => {

    useEffect(() => {
        const container = document.getElementById(`particle__container--${id}`);
        container.style.top = `${top}`;
        container.style.left = `${left}`;
        container.style.width = `${size}`;
        container.style.position = "absolute";
        container.style.filter = `drop-shadow(${shadow} #2b2b2b)`;

        const img = document.createElement("IMG");
        img.className = `particle__img--${id}`;
        img.src = `${imgParticula}`;
        img.style.width = '100%';
        img.style.animation = `giro ${tiempoGiro} linear infinite`;
        if (!active) {
            img.style.filter = 'blur(0.5px)';
        }
        container.appendChild(img);

        const particlesContainer = document.getElementById('particles__container');
        particlesContainer.appendChild(container);

    }, [active, id, left, shadow, size, tiempoGiro, top]);

    return (
        <>
            <div className="particle__container" id={`particle__container--${id}`}>
            </div>
        </>
    )
}

export default Particula;
