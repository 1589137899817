import React from 'react';
import './Tecnologia.css';
import imgTecnologia from '../images/tecnologia.jpg';
import imgPilaCombustible from '../images/pila-de-combustible.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Tecnologia = () => {
    const { t } = useTranslation();
    
    return(
        <>
            <Helmet>
                <title>Tecnología del hidrógeno</title>
                <meta name="description" content="El hidrógeno es el elemento más ligero de la naturaleza, así como el más abundante en el universo, totalizando más del 70%. Se halla en las estrellas jóvenes, en polvo interestelar y en las enormes nubes de gas suspendidas en el espacio, pero la mayor parte de él se encuentra formando diversos compuestos químicos." />
            </Helmet>

            <div className="page__container">
                <h2>{t('TECNOLOGIA__H2')}</h2>
                <img src={imgTecnologia} className="page__img" alt="" />
                <h3>{t('TECNOLOGIA__H3-1')}</h3>
                    <p>{t('TECNOLOGIA__P1')}</p>
                <p>{t('TECNOLOGIA__P2')}</p>
                <p>{t('TECNOLOGIA__P3')}</p>
                <p>{t('TECNOLOGIA__P4')}</p>
                <p>{t('TECNOLOGIA__P5')}</p>
                <p>{t('TECNOLOGIA__P6')}</p>
                <h3>{t('TECNOLOGIA__H3-2')}</h3>
                <p>{t('TECNOLOGIA__P7')}</p>
                <img src={imgPilaCombustible} className="page__img" alt="" />
                <div className="tecnologia__funcionamiento-pila">
                    <p>{t('TECNOLOGIA__LIST1')}</p>
                    <p>{t('TECNOLOGIA__LIST2')}</p>
                    <p>{t('TECNOLOGIA__LIST3')}</p>
                    <p>{t('TECNOLOGIA__LIST4')}</p>
                </div>

            </div>  
        </>
    )
}
export default Tecnologia;