import React from 'react';
import './Background.css';
import Particula from './Particula';
import Particles from 'react-particles-js';
import imgBackground from '../images/background.jpg';
import { useTranslation } from 'react-i18next';

const Background = () => {
    const { t } = useTranslation();

    return(
        <div className="main__background" style={{ backgroundImage: `url(${imgBackground})` }} >
            <div className="title__container">
                <h1><span className="title__word--highlight">{t('BG__BIENVENIDO-1A')}</span>{t('BG__BIENVENIDO-1B')}</h1>
                <h2>{t('BG__BIENVENIDO-2A')}<span className="title__word--highlight">{t('BG__BIENVENIDO-2B')}</span></h2>
            </div>
            <div className="react-particles__container">
                <Particles 
                    params={{ 
                        particles: { 
                            number: { 
                                value: 200, 
                                density: { 
                                    enable: true, 
                                    value_area: 2000, 
                                },
                            },
                            shape: { // La forma de los puntos
                                polygon: {
                                    nb_sides: 2,
                                },
                            },
                            size: { // Tamaño de las partículas
                                value: 3,
                                random: true,
                            },
                            color: { // Color de las partículas
                                value: "#ffffff",
                            },
                            line_linked: {
                                distance: 100, // Distancia a las que se unen las partículas con las líneas
                                color: "#b5b5b5", // Color de las lineas
                                opacity: 0.2,
                                width: 1,
                            },
                            move: {  // Velocidad de movimiento de las partículas
                                speed: 3,
                            }
                        }, 
                    }} 
                /> 
            </div>
            <div className="particles__container" id="particles__container">
                
                <Particula id='1' size='200px' top='55px' left='24%' tiempoGiro='9s' shadow='30px 30px 3px' active={true} />
                <Particula id='2' size='30px' top='200px' left='87%' tiempoGiro='3.2s' shadow='8px 8px 2px' active={true} />
                <Particula id='3' size='50px' top='30px' left='75%' tiempoGiro='5s' shadow='6px 6px 2px' active={true} />
                <Particula id='4' size='40px' top='50px' left='7%' tiempoGiro='4.2s' shadow='25px 25px 3px' active={true} />

            </div>
        </div>
    )
}

export default Background;