import React from 'react';
import './Proyectos.css';
import imgProyectos from '../images/proyectos.jpg';
import imgEstacion from '../images/estacion-hidrogeno.jpg';
import imgCoche from '../images/coche-hidrogeno.jpg';
import imgScooter from '../images/scooter-hidrogeno.jpg';
import imgCasa from '../images/casa-portatil.jpg';
import imgCarretilla from '../images/carretilla-elevadora.jpg';
import imgGenerador from '../images/generador-seguridad.jpg';
import imgHotel from '../images/hotel-hidrogeno.jpg';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';


const Proyectos = () => {
    const { t } = useTranslation();

    return(
        <>
            <Helmet>
                <title>Proyectos con hidrógeno</title>
                <meta name="description" content="Algunos de nuestros proyectos con hidrógeno: Estación de hidrógeno. Coche de hidrógeno. Scooter de hidrógeno. Casa de hidrógeno móvil. Carretilla elevadora. Generador de seguridad hidrógeno. Green Hotel Lifestyle" />
            </Helmet>
            
            <div className="page__container proyectos__container">
                <h2>{t('PROYECTOS__H2')}</h2>
                <img src={imgProyectos} className="page__img" alt="" />
                <h4 className="proyectos__h4">{t('PROYECTOS__H4')}</h4>
                <ul className="proyectos__list">
                    <li><a href="#estacion">{t('PROYECTOS__LIST1')}</a></li>
                    <li><a href="#coche">{t('PROYECTOS__LIST2')}</a></li>
                    <li><a href="#scooter">{t('PROYECTOS__LIST3')}</a></li>
                    <li><a href="#casa">{t('PROYECTOS__LIST4')}</a></li>
                    <li><a href="#carretilla">{t('PROYECTOS__LIST5')}</a></li>
                    <li><a href="#generador">{t('PROYECTOS__LIST6')}</a></li>
                    <li><a href="#hotel">{t('PROYECTOS__LIST7')}</a></li>
                </ul>
                <section>
                    <h3 id="estacion">{t('PROYECTOS__ESTACION-H3')}</h3>
                    <img src={imgEstacion} className="page__img" alt=""/>
                    <p>{t('PROYECTOS__ESTACION-P1')}</p>
                    <p>{t('PROYECTOS__ESTACION-P2')}</p>
                </section>

                <section>
                    <h3 id="coche">{t('PROYECTOS__COCHE-H3')}</h3>
                    <img src={imgCoche} className="page__img" alt=""/>
                    <p>{t('PROYECTOS__COCHE-P1')}</p>
                    <p>{t('PROYECTOS__COCHE-P2')}</p>
                </section>

                <section>
                    <h3 id="scooter">{t('PROYECTOS__SCOOTER-H3')}</h3>
                    <img src={imgScooter} className="page__img" alt=""/>
                    <p>{t('PROYECTOS__SCOOTER-P1')}</p>
                    <p>{t('PROYECTOS__SCOOTER-P2')}</p>
                    <p>{t('PROYECTOS__SCOOTER-P3')}</p>
                </section>

                <section>
                    <h3 id="casa">{t('PROYECTOS__CASA-H3')}</h3>
                    <img src={imgCasa} className="page__img" alt=""/>
                    <p>{t('PROYECTOS__CASA-P1')}</p>
                    <p>{t('PROYECTOS__CASA-P2')}</p>
                    <p>{t('PROYECTOS__CASA-P3')}</p>
                </section>

                <section>
                    <h3 id="carretilla">{t('PROYECTOS__CARRETILLA-H3')}</h3>
                    <img src={imgCarretilla} className="page__img proyectos__img--carretilla" alt=""/>
                    <p>{t('PROYECTOS__CARRETILLA-P1')}</p>
                    <p>{t('PROYECTOS__CARRETILLA-P2')}</p>
                </section>

                <section>
                    <h3 id="generador">{t('PROYECTOS__GENERADOR-H3')}</h3>
                    <img src={imgGenerador} className="page__img" alt=""/>
                    <p>{t('PROYECTOS__GENERADOR-P1')}</p>
                    <p>{t('PROYECTOS__GENERADOR-P2')}</p>
                    <p>{t('PROYECTOS__GENERADOR-P3')}</p>
                    <p>{t('PROYECTOS__GENERADOR-P4')}</p>
                    <ul className="generador__ul">
                        <li>{t('PROYECTOS__GENERADOR-LIST1')}</li>
                        <li>{t('PROYECTOS__GENERADOR-LIST2')}</li>
                        <li>{t('PROYECTOS__GENERADOR-LIST3')}</li>
                        <li>{t('PROYECTOS__GENERADOR-LIST4')}</li>
                    </ul>
                </section>

                <section>
                    <h3 id="hotel">{t('PROYECTOS__HOTEL-H3')}</h3>
                    <img src={imgHotel} className="page__img" alt=""/>
                    <p>{t('PROYECTOS__HOTEL-P1')}</p>
                    <p>{t('PROYECTOS__HOTEL-P2')}</p>
                    <p>{t('PROYECTOS__HOTEL-P3')}</p>
                    <p>{t('PROYECTOS__HOTEL-P4')}</p>
                    <p>{t('PROYECTOS__HOTEL-P5')}</p>
                </section>

            </div>  
        </>
    )
}
export default Proyectos;