import React from 'react';
import './ComunidadHidrogeno.css';
import imgGolfVistaHomes from '../images/golf-vista-homes.jpg';
import imgMartinMiguel from '../images/martin-miguel.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const ComunidadHidrogeno = () => {
    const { t } = useTranslation();
    
    return(
        <>
        <Helmet>
            <title>Comunidad del hidrógeno</title>
            <meta name="description" content="Nuestra empresa quiere participar en el desarrollo de esta nueva tecnología. Por ello, hemos creado el proyecto “Hydrogenium Center”, para acoger diversas tecnologías, con el fin de realizar una infraestructura completamente independiente de las fuentes de energías convencionales e investigar en las diferentes posibilidades utilizar el hidrógeno de manera diaria." />
        </Helmet>

        <div className="page__container">
            <h2>{t('COMUNIDAD__H2')}</h2>
            <img src={imgGolfVistaHomes} className="page__img" alt="" />
            <h3>{t('COMUNIDAD__H3-1')}</h3>
            <p>{t('COMUNIDAD__P1')}</p>
            <p>{t('COMUNIDAD__P2')}</p>
            <p>{t('COMUNIDAD__P3')}</p>
            <p><b>{t('COMUNIDAD__P4')}</b></p>
            <p>{t('COMUNIDAD__P5')}</p>
            <h3>{t('COMUNIDAD__H3-2')}</h3>
            <p>{t('COMUNIDAD__P6')}</p>
            <img src={imgMartinMiguel} className="page__img comunidad-hidrogeno__img--map" alt="" />
            <p>{t('COMUNIDAD__P7')}</p>
            <p><b>{t('COMUNIDAD__P8')}</b></p>
            <div className="comunidad-hidrogeno__fases">
                <h3>{t('COMUNIDAD__H3-3')}</h3>
                <p>{t('COMUNIDAD__P9')}</p>
                <h3>{t('COMUNIDAD__H3-4')}</h3>
                <p>{t('COMUNIDAD__P10')}</p>
                <p>{t('COMUNIDAD__P11')}</p>
                <h3>{t('COMUNIDAD__H3-5')}</h3>
                <p>{t('COMUNIDAD__P12')}</p>
                <p>{t('COMUNIDAD__P13')}</p>
            </div>
        </div>
    </>
    )
}
export default ComunidadHidrogeno;