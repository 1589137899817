import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import './Footer.css';

const Footer = () => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return(
        <footer className="footer__container">
            <span>{t('FOOTER_COPYRIGHT')}&nbsp;&copy; 2012-{currentYear} Hydrogenium.es -&nbsp;</span>
            <span>{t('FOOTER_DERECHOS-RESERVADOS')}</span>
            <LanguageSelector location="FOOTER" />
         </footer>
    )
}

export default Footer;