import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css';

const LanguageSelector = ({ location, changeLanguageHandler }) => {
    const { t, i18n } = useTranslation();
    function onChange(e) {
        i18n.changeLanguage(e.target.value);
        // Esta función viene sólo del Header no del footer
        if (location === 'HEADER') {
            changeLanguageHandler();
        }
    }

    return(
        <>
            <div className="language-selector__container">
                <span className="material-icons language-selector__icon">language</span>
                <select className="language-selector" value={i18n.language} onChange={(e) => onChange(e)}>
                    <option value="es">{t(`LANGUAGE-SELECTOR__${location}__ESPANOL`)}</option>
                    <option value="en">{t(`LANGUAGE-SELECTOR__${location}__INGLES`)}</option>
                    <option value="it">{t(`LANGUAGE-SELECTOR__${location}__ITALIANO`)}</option>
                    <option value="ch">{t(`LANGUAGE-SELECTOR__${location}__CHINO`)}</option>
                </select>
            </div>
        </>
    )
}

export default LanguageSelector;