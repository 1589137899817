import React from 'react';
import './CentroEuropeo.css';
import imgCentroEuropeo from '../images/centro-europeo.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const CentroEuropeo = () => {
    const { t } = useTranslation();
    
    return(
        <>
            <Helmet>
                <title>Hydrogenium European Center</title>
                <meta name="description" content="Hydrogenium European Center contará con un aula de formación y experimentación para empleados, becarios e investigadores internacionales, así como con una organización que propondrá cursos para profesionales del sector e incentivara el ingenio y la vocación emprendedora." />
            </Helmet>
            
            <div className="page__container">
                <h2>{t('CENTRO__H2')}</h2>
                <img src={imgCentroEuropeo} className="page__img" alt="" />
                <h3>{t('CENTRO__H3-1')}</h3>
                <p>{t('CENTRO__P1')}</p>
                <p>{t('CENTRO__P2')}</p>
                <p>{t('CENTRO__P3')}</p>
                <h3>{t('CENTRO__H3-2')}</h3>
                <p>{t('CENTRO__P4')}</p>
                <h3>{t('CENTRO__H3-3')}</h3>
                <p>- {t('CENTRO__LIST1')}</p>
                <p>- {t('CENTRO__LIST2')}</p>
                <p>- {t('CENTRO__LIST3')}</p>
                <p>- {t('CENTRO__LIST4')}</p>
                <p>- {t('CENTRO__LIST5')}</p>
            </div>
        </>
    )
}
export default CentroEuropeo;