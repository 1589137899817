
import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Background from './components/Background';
import Inicio from './pages/Inicio';
import CentroEuropeo from './pages/CentroEuropeo';
import ComunidadHidrogeno from './pages/ComunidadHidrogeno';
import Tecnologia from './pages/Tecnologia';
import Proyectos from './pages/Proyectos';
import Transporte from './pages/Transporte';
import Contacto from './pages/Contacto';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <>
    <HelmetProvider>
      <Router>

        <Header />
    
        <main> 
          <Background />

          <Switch>
            <Route path="/hydrogen-european-center" component={CentroEuropeo} />
            <Route path="/hydrogen-comunity" component={ComunidadHidrogeno} />
            <Route path="/tecnology" component={Tecnologia} />
            <Route path="/projects" component={Proyectos} />
            <Route path="/transport" component={Transporte} />
            <Route path="/contact" component={Contacto} />
            <Route path="/" component={Inicio} />
          </Switch>
        
        </main>

      </Router>

      <Footer />
    </HelmetProvider>

    </>
  );
}

export default App;
